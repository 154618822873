<template>
    <div>
        <div  class="mb-2 hidden-md-and-down"
              @click.stop="openFile(Mitgliederliste.file)"
        >
            <div class="kath-sz-card">
                {{Mitgliederliste.name}}
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Kantonskirchenrat_Ersatzmitglieder_Kirchenrat",
        methods: {
            openFile(filename){
                window.open( filename, '_blank');
            },
        },
        computed: {
            Mitgliederliste () {
                return this.$store.getters.ErsatzMitgliederKantonskirchenrates
            }
        }
    }
</script>

<style scoped>

</style>